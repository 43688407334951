<template>
  <div class="customerServiceBox">
    <img
      id="customerService"
      @mouseenter="hoverClass"
      src="../assets/img/customerService.png"
    />
    <div id="customerServices" @mouseleave="hoverClassOut">
      <p class="titles">联系</p>

      <p class="title1">客服</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {
    document.getElementById('customerService').style.display = 'block';
    document.getElementById('customerServices').style.display = 'none';
  },
  methods: {
    hoverClass() {
      document.getElementById('customerService').style.display = 'none';
      document.getElementById('customerServices').style.display = 'block';
    },
    hoverClassOut() {
      document.getElementById('customerService').style.display = 'block';
      document.getElementById('customerServices').style.display = 'none';
    },
  },
};
</script>
<style scoped>
#customerService {
  position: fixed;
  top: 400px;
  right: 50px;
  width: 55px;
  height: 55px;
  border-radius: 5px;
}
#customerServices {
  position: fixed;
  top: 400px;
  right: 50px;
  width: 55px;
  height: 55px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  background-color: #ff552e;
  text-align: center;
}
#customerServices .titles {
  margin-top: 8px;
}
</style>