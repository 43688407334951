<template>
  <div class="Leftmenus">
    <el-row class="tac">
      <el-col :span="12">
        <div class="tac_title">个人中心</div>
        <el-menu
          :default-active="$route.path"
          class="el-menu-vertical-demo el-menus"
          text-color="#595757"
          active-text-color="#FF552E"
          :router="true"
          @select="handleSelect"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="/jobwanted">
            <span slot="title">我的发布</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">我的收藏</span>
          </el-menu-item>
          <el-submenu index="1">
            <template slot="title">
              <span>我的求职</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">求职首页</el-menu-item>
              <el-menu-item index="/jobwanted/resumeStatus"
                >我的简历</el-menu-item
              >
               <el-menu-item index="/jobwanted/resumehistory">简历投递记录</el-menu-item>
              <el-menu-item index="/jobwanted/resumeDownload">谁下载了我的简历</el-menu-item>
              <el-menu-item index="/jobwanted/interviewInvitation">面试邀请</el-menu-item>
              <el-menu-item index="1-2">隐私设置</el-menu-item>
              <el-menu-item index="1-1">会员中心</el-menu-item>
              <el-menu-item index="1-2">投诉管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">
              <span>我的招聘</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">职位管理</el-menu-item>
              <el-menu-item index="1-2">简历管理</el-menu-item>
              <el-menu-item index="1-1">快速招人</el-menu-item>
              <el-menu-item index="1-2">简历套餐</el-menu-item>
              <el-menu-item index="1-1">自助订购</el-menu-item>
              <el-menu-item index="1-2">成为会员</el-menu-item>
              <el-menu-item index="1-1">被投诉管理</el-menu-item>
              <el-menu-item index="1-2">账号管理</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="2">
            <span slot="title">我的浏览</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">我的评价</span>
          </el-menu-item>
          <el-submenu index="4">
            <template slot="title">
              <span>我的订购</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">我的订单</el-menu-item>
              <el-menu-item index="1-2">我的合同</el-menu-item>
              <el-menu-item index="1-1">我的资源</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">
              <span>客户服务</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">我的反欺诈</el-menu-item>
              <el-menu-item index="1-2">处罚申诉</el-menu-item>
              <el-menu-item index="1-1">违规记录</el-menu-item>
              <el-menu-item index="1-2">举报中心</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-menu-item index="2">
            <span slot="title">帮助中心</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">刷新管理</span>
          </el-menu-item>
          <el-submenu index="6">
            <template slot="title">
              <span>我的房源</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="1-1">我的委托</el-menu-item>
              <el-menu-item index="1-2">我的微房产</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-col>
    </el-row>
    <el-row class="tac">
      <el-col :span="12">
        <div class="tac_title">账户设置</div>
        <el-menu
          default-active="2"
          class="el-menu-vertical-demo"
          @open="handleOpen"
          @close="handleClose"
        >
          <el-menu-item index="2">
            <span slot="title">个人资料</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">账号绑定</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">认证管理</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">密码设置</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">安全中心</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">消息管理</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">子母账号</span>
          </el-menu-item>
          <el-menu-item index="2">
            <span slot="title">注销账号</span>
          </el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    handleSelect(key, keyPath){
      console.log(key, keyPath);
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClick(row) {
      console.log(row);
    },
  },
};
</script>

<style scoped>
.Leftmenus {
  width: 210px;
  margin-right: 50px;
  text-align: center;
}
.el-menu {
  border: none;
  list-style: none;
  position: relative;
  margin: 0;
  /* margin-left: 100px; */
  padding-left: 0;
  background-color: #fff;
  width: 210px;
}
.tac_title {
  width: 114px;
  height: 50px;
  font-size: 20px;
  font-weight: 600;
  color: #595757;
  border-bottom: 1px solid #eaeaea;
  line-height: 50px;
  margin-left: 45px;
}
.tac_title:hover {
  color: #ed6c1f;
}
.el-menu-item.is-active {
  color: #595757;
}
.el-menu-item:hover {
  color: #ed6c1f !important;
}
.el-submenu__title:hover {
  color: #ed6c1f !important;
}
.el-menu-item,
.el-submenu__title {
  height: 45px;
  line-height: 45px;
  position: relative;
  font-size: 16px;
  /* -webkit-box-sizing: border-box; */
  white-space: nowrap;
  list-style: none;
}
.el-submenu__icon-arrow {
  position: absolute;
  top: 50%;
  right: 50px;
  margin-top: -7px;
  transition: transform 0.3s;
  font-size: 12px;
}
.el-submenu .el-menu-item {
  height: 50px;
  font-size: 12px;
  line-height: 50px;
  padding: 0 45px;
  min-width: 200px;
}
</style>