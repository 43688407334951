<template>
  <div>
    <Header titleurl="4"></Header>
    <div class="boxStatus">
      <Menu></Menu>
      <div class="RightShow">
        <div class="resumeTitle">我的简历</div>
        <el-table :data="tableData" border style="width: 100%">
          <el-table-column fixed label="简历名称" width="150">
            <template slot-scope="scope">
              <div class="nameBox">
                <p class="danger">{{ scope.row.date }}</p>
                <el-tag type="danger">默认</el-tag>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="类型" width="80">
          </el-table-column>
          <el-table-column prop="province" label="完整度" width="150">
            <template>
              <el-progress :percentage="80" color="#e6a23c"></el-progress>
            </template>
          </el-table-column>
          <el-table-column label="公开设置" width="100">
            <template slot-scope="scope">
              <div class="tableColumCity" @click="tableColumCity">
                {{ scope.row.city }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" label="下载/浏览" width="100">
          </el-table-column>
          <el-table-column prop="zip" label="面试邀请" width="100">
          </el-table-column>
          <el-table-column prop="color" label="更新时间" width="100">
          </el-table-column>
          <el-table-column fixed="right" label="操作" width="100">
            <template slot-scope="scope">
              <el-button
                @click="handleClick(scope.row)"
                type="text"
                size="small"
                >修改</el-button
              >
              <el-button type="text" size="small">预览</el-button>
              <el-button type="text" size="small">删除</el-button>
              <el-button type="text" size="small">置顶</el-button>
              <el-button type="text" size="small">刷新</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="RightShowIocn">
          <div class="RightShowBtn">
            <el-button @click="dialogStatus">修改求职状态</el-button>
            <div class="RightShowBtnTit">当前状态：求职中</div>
          </div>
          <div class="RightShowBtn2">
            <el-button class="btn2" @click="resumeGoTo">创建全职简历</el-button>
            <el-button class="btn1">创建兼职简历</el-button>
          </div>
        </div>
        <div class="explain">
          <div class="explainBox">
            <img src="../../assets/img/explain.png" style="margintop: -1px" />
            <div class="explainTit">说明</div>
          </div>
          <p class="explainP1">
            1.置顶：将您的信息固定排在页面最顶部，不会被其他信息挤下去。<a
              href="/"
              class="lineNone"
              >查看帮助</a
            >
          </p>
          <p class="explainP">
            2.刷新：刷新简历完全免费，刷新后时间变为最新，招聘方在搜索简历时会优先显示。
          </p>
          <p class="explainP">
            3.设置默认：设置默认简历后，投递时会直接为您投递该简历。
          </p>
          <p class="explainP">
            4.求职状态设置：找到工作后，可通过修改求职状态让您免受打扰。
          </p>
        </div>
        <!-- 修改求职状态 -->
        <el-dialog title="求职状态" :visible.sync="dialogVisible" width="30%">
          <div>
            <el-radio v-model="radio" label="1" style="marginbuttom: 10px"
              >求职中 我们会按照您对简历的操作为您提供更好的求职服务。</el-radio
            >
            <el-radio v-model="radio" label="2"
              >已找到工作
              我们会将您的求职状态同步到企业，让您免受打扰。</el-radio
            >
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button>确 定</el-button>
          </span>
        </el-dialog>
        <!-- 公开 -->
        <el-dialog title="公开" :visible.sync="dialogVisible1" width="30%">
          <div>
            <el-radio v-model="radio" label="1" style="marginbuttom: 10px"
              ><span class="comMargin">公开</span>
              <span>允许所有招聘方下载您的简历。</span></el-radio
            >
            <el-radio v-model="radio" label="2">
              <span class="comMargin">对认证招聘方公开</span>
              <span>只允许58同城认证招聘方下载您的简历。</span>
            </el-radio>
            <el-radio v-model="radio" label="3"
              ><span class="comMargin">保密</span>
              <span
                >不允许招聘方主动查看您的简历，只有您应聘的招聘方才可查看。</span
              ></el-radio
            >
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button type="primary">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <Service></Service>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '../../components/header';
import Footer from '../../components/footer';
import Menu from '../../components/menu';
import Service from '../../components/serviceBox';

export default {
  name: 'resumeStatus',
  components: {
    Header,
    Footer,
    Menu,
    Service,
  },
  data() {
    return {
      entityId: '54189',
      tableData: [
        {
          date: 'cv 1-2年',
          name: '全职',
          province: '',
          city: '公开',
          address: '0/ 1',
          zip: 0,
          color: '2020-09-27',
        },
      ],
      // 修改求职状态
      dialogVisible: false,
      // 公开
      dialogVisible1: false,
      radio: '1',
    };
  },
  methods: {
    dialogStatus() {
      this.dialogVisible = true;
    },
    tableColumCity() {
      this.dialogVisible1 = true;
    },
    resumeGoTo() {
      this.$router.push('/jobwanted/resume');
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="../../assets/style/recruit/resumeStatus.css" scoped>
</style>